import { isOpenUI } from '@sas-dvr/internal-va-react-core/utils/isOpenUI';

// Webpack now sets __webpack_public_path__ using document.currentScript automatically,
// but this logic is still needed to make the value usable in requests
declare let __webpack_public_path__: string;
let pubPath = '';
const dataProps: {
  imagesOnMobile?: string;
} = {};

(function setVaSdkWebpackPublicPath() {
  let oldWebpackPath = '';
  try {
    oldWebpackPath = __webpack_public_path__;
  } catch {
    // thrown when __webpack_public_path__ isn't defined (jest)
    console.warn('va-sdk:WARN: did not set webpack public path');
    return;
  }

  function setWebpackPathRelativeToScript(src: string, scriptEl?: HTMLOrSVGScriptElement): void {
    const newWebpackPath = src.substring(0, src.lastIndexOf('/') + 1);
    pubPath = newWebpackPath;
    __webpack_public_path__ = newWebpackPath;
    console.info(
      `va-sdk:INFO: set __webpack_public_path__ from "${oldWebpackPath}" to "${newWebpackPath}" using "${src}"`
    );
    if (scriptEl?.dataset.imagesOnMobile) {
      dataProps.imagesOnMobile = scriptEl?.dataset.imagesOnMobile;
    }
  }

  // if OpenUI, use the standard module resolution
  if (isOpenUI) {
    const scriptSrc = (window as any).jQuery?.sap?.getResourcePath(
      'sas/vasdk/lib/vaReportComponents.js'
    );
    if (scriptSrc) {
      setWebpackPathRelativeToScript(scriptSrc);
      return;
    }
  }

  // if currentScript is not null, then this script is **currently being processed** and is not an es6 module.
  if (document.currentScript) {
    const scriptSrc = (document.currentScript as HTMLScriptElement).src;
    if (scriptSrc) {
      setWebpackPathRelativeToScript(scriptSrc, document.currentScript);
      return;
    }
  }

  // if document.currentScript didn't work, try to find our <script> tag
  const scriptSrcPattern = /\/va-report-components.js$/;

  const scriptTags = document.getElementsByTagName('script'),
    scriptTagsLength = scriptTags.length;

  for (let i = 0; i < scriptTagsLength; ++i) {
    const script = scriptTags[i],
      scriptSrc = script.src;
    if (scriptSrcPattern.test(scriptSrc)) {
      setWebpackPathRelativeToScript(scriptSrc, script);
      return;
    }
  }

  console.warn('va-sdk:WARN: did not set webpack public path');
})();

export const publicPath = pubPath;
export const scriptData = dataProps;
