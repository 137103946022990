import { publicPath } from './init/fixWebpackPublicPath';
import { defineCustomElements } from './elements';
import { displayImagesForMobile } from './elements/dynamicImports/isMobile';
import { fireLoadedEvent } from './init/loadedEvent';
import { whenCssLoaded } from './init/loadCss';
import type * as vaReportComponents from './exports/vaReportComponents';

import { initNovaCore } from './init/initNovaCore';
import { loadLTJS } from './init/loadLTJS';
import { loadCss } from './init/loadCss';

import '@sas/nova-commons/embedded.css';

declare const ROOT_CLASS_NAME: string;
declare global {
  interface Window {
    vaReportComponents: typeof vaReportComponents | undefined;
  }
}

initNovaCore();
loadCss(publicPath + 'style.css');

const mobileImages = displayImagesForMobile();
if (!mobileImages) {
  loadLTJS({ resourceUrl: publicPath + 'assets/' });
}
defineCustomElements({
  importType: mobileImages ? 'mobile' : 'dynamic',
  async initialize(imports) {
    if (imports.type === 'dynamic') {
      imports.setupLtjsRuntime();
      imports.setupLtjsEnvironment();
      imports.initRootStyles(ROOT_CLASS_NAME);
      imports.setHonorLocalFormatSettings(true);
      imports.initNovaI18n();

      await whenCssLoaded();
      window.vaReportComponents = imports.vaReportComponents;
      fireLoadedEvent();
    }
  },
});
