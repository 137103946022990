import { ltjsGlobalNamespace } from '@sas-dvr/ltjs-commons/ltjsInit';
// TODO: don't create if someone has already added a style element?
let resolveLoaded: (() => void) | undefined;
let rejectLoaded: (() => void) | undefined;
let hasLoaded: 'loading' | 'error' | 'loaded' = 'loading';
let promise: Promise<void> | undefined;

export function loadCss(styleUrl: string) {
  // MiniCssExtractPlugin can't handle CSS in the initial chunk, so add it manually
  const vaCss = document.createElement('link');
  vaCss.rel = 'stylesheet';
  vaCss.href = styleUrl;

  // In jest, the CSS fails to load but is not needed.
  if (ltjsGlobalNamespace.jest) {
    hasLoaded = 'loaded';
  } else {
    document.head.appendChild(vaCss);
  }
  vaCss.onload = function () {
    hasLoaded = 'loaded';
    resolveLoaded?.();
  };
  vaCss.onerror = function () {
    console.error('va-sdk:ERROR: css failed to load');
    hasLoaded = 'error';
    rejectLoaded?.();
  };
}

export function whenCssLoaded(): Promise<void> {
  if (hasLoaded === 'loaded') {
    return Promise.resolve();
  } else if (hasLoaded === 'error') {
    return Promise.reject();
  }
  if (!promise) {
    promise = new Promise((resolve, reject) => {
      resolveLoaded = resolve;
      rejectLoaded = reject;
    });
  }
  return promise;
}
