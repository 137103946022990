import { ltjsGlobalNamespace } from '@sas-dvr/ltjs-commons/ltjsInit';
import { loadScript } from '@sas-dvr/ltjs-commons/util/ltjsLoaderUtil';

export function loadLTJS({
  resourceUrl,
  getResourceUrl,
}: {
  resourceUrl?: string;
  getResourceUrl?: typeof ltjsGlobalNamespace.getResourceUrl;
}) {
  if (!resourceUrl && !getResourceUrl) {
    throw new Error('Invalid config');
  }
  if (resourceUrl) {
    getResourceUrl = function (resource) {
      return resourceUrl + resource;
    };
  }
  ltjsGlobalNamespace.getResourceUrl = getResourceUrl;

  // va-sdk UMD builds exclude ICU assets, so always use browser collation
  ltjsGlobalNamespace.useBrowserCollation = true;
  loadScript([]);
}
