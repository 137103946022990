import { initializeDynamicImports } from './dynamicImports';
import { SASReportAttributes, SASReportElement } from './SASReportElement';
import { SASReportObjectAttributes, SASReportObjectElement } from './SASReportObjectElement';
import { SASReportPageAttributes, SASReportPageElement } from './SASReportPageElement';
import './elements.css';

export { SASReportElement, SASReportObjectElement, SASReportPageElement };
export type { SASReportAttributes, SASReportObjectAttributes, SASReportPageAttributes };

export function defineCustomElements(
  dynamicImportConfig?: Parameters<typeof initializeDynamicImports>[0]
) {
  initializeDynamicImports(dynamicImportConfig);
  if (!customElements.get('sas-report')) {
    customElements.define('sas-report', SASReportElement);
  }
  if (!customElements.get('sas-report-object')) {
    customElements.define('sas-report-object', SASReportObjectElement);
  }
  if (!customElements.get('sas-report-page')) {
    customElements.define('sas-report-page', SASReportPageElement);
  }
}
