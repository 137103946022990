import { AbstractReportAttributes, AbstractReportElement } from './AbstractReportElement';
import { SASReportObjectProps } from '../components/SASReportObject';
import { getImports } from './dynamicImports';
import type { ObjectHandle } from '../handles';

function validateHideLoadImageValue(value?: null | boolean | string) {
  if (value && typeof value === 'string') {
    const lower = value.toLocaleLowerCase();
    return ['true', 'false'].indexOf(lower) > -1;
  }
  return true;
}

/**
 * @public
 */
export type SASReportObjectAttributes = AbstractReportAttributes<
  SASReportObjectElement,
  'objectName' | 'reportContextKey' | 'hideLoadImage'
>;

/**
 * @public @sealed
 */
export class SASReportObjectElement extends AbstractReportElement<{
  /** @internal */
  props: SASReportObjectProps;
}> {
  /**
   * @internal
   */
  protected _getRenderer() {
    return getImports()?.SASReportObject;
  }

  /**
   * @internal
   */
  static get observedAttributes() {
    return [...super.observedAttributes, 'objectname', 'reportcontextkey', 'hideloadimage'];
  }

  /**
   * @internal
   */
  protected _getContextKey(props: SASReportObjectProps) {
    return 'url' in props
      ? `${props.url}:${props.reportUri}:${props.reportContextKey}:${props.objectName}`
      : `${props.packageUri}:${props.reportContextKey}:${props.objectName}`;
  }

  /**
   * @internal
   */
  protected getRenderProps(): SASReportObjectProps | null {
    const commonProps = this.getCommonProps();

    if (commonProps && this.objectName) {
      return {
        ...commonProps,
        objectName: this.objectName,
        reportContextKey: this.reportContextKey,
        hideLoadImage: this.hideLoadImage,
      };
    } else {
      return null;
    }
  }

  get objectName() {
    return this.getAttribute('objectName');
  }
  set objectName(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('objectName', value);
    } else {
      this.removeAttribute('objectName');
    }
  }

  get reportContextKey() {
    return this.getAttribute('reportContextKey') || undefined;
  }

  set reportContextKey(value) {
    if (value && typeof value === 'string') {
      this.setAttribute('reportContextKey', value);
    } else {
      this.removeAttribute('reportContextKey');
    }
  }

  get hideLoadImage() {
    if (!this.hasAttribute('hideLoadImage')) return false;

    const value = this.getAttribute('hideLoadImage');
    if (typeof value === 'string') {
      switch (value.toLocaleLowerCase()) {
        case 'true':
        case '':
          return true;
        case 'false':
          return false;
        default:
          return false;
      }
    }
    return true;
  }

  set hideLoadImage(value) {
    if (typeof value === 'string') {
      if (!validateHideLoadImageValue(value)) {
        console.warn(`Invalid value for hideLoadImage: ${value}`);
        this.removeAttribute('hideLoadImage');
      } else {
        this.setAttribute('hideLoadImage', value);
      }
    } else if (typeof value === 'boolean') {
      this.setAttribute('hideLoadImage', value.toString());
      return;
    } else if (!value) {
      this.removeAttribute('hideLoadImage');
    } else {
      console.warn(`Invalid value for hideLoadImage: ${value}`);
      this.removeAttribute('hideLoadImage');
    }
  }

  /**
   * @internal
   */
  private _objectHandlePromise?: Promise<ObjectHandle>;
  /**
   * @internal
   */
  private _rejectObjectHandleCallback?: (reason: string) => void;
  /**
   * @internal
   */
  protected _invalidateHandleRequests(reason: string) {
    super._invalidateHandleRequests(reason);
    this._rejectObjectHandleCallback?.(reason);
    this._rejectObjectHandleCallback = undefined;
    this._objectHandlePromise = undefined;
  }
  /**
   * @internal not publicly documented
   */
  getObjectHandle(): Promise<ObjectHandle> {
    const objectName = this.objectName;
    if (!objectName) {
      return Promise.reject('Cannot get an ObjectHandle before setting objectName');
    }
    if (!this._objectHandlePromise) {
      this._objectHandlePromise = Promise.race([
        // Resolve with the handle for the current object name
        this.getReportHandle().then((handle) => handle.getObjectHandle(objectName)),
        // Or reject if the element attributes change
        new Promise<ObjectHandle>((_accept, reject) => {
          this._rejectObjectHandleCallback = reject;
        }),
      ]);
    }
    return this._objectHandlePromise;
  }
}
