// There should only be type imports here
import type * as MobileImports from './mobileImportsImpl';
import type * as DynamicImports from './dynamicImportsImpl';

export type DynamicImportsType =
  | ({
      type: 'mobile';
    } & typeof MobileImports)
  | ({
      type: 'dynamic';
    } & typeof DynamicImports);

let imports: DynamicImportsType | undefined;
let elementsToInvalidate: Map<Object, () => void> | undefined = new Map();

/**
 * Returns the dynamic imports if they have already been loaded. If the dynamic imports have not been loaded
 * undefined will be returned.
 */
export function getImports(): DynamicImportsType | undefined {
  return imports;
}

export async function initializeDynamicImports(config?: {
  importType?: DynamicImportsType['type'];
  initialize?: (imports: DynamicImportsType) => void | Promise<void>;
}) {
  const importsImpl: DynamicImportsType = await (config?.importType === 'mobile'
    ? import('./mobileImportsImpl').then((imports) => ({ type: 'mobile', ...imports }))
    : import('./dynamicImportsImpl').then((imports) => ({ type: 'dynamic', ...imports })));
  if (config?.initialize) {
    await config?.initialize(importsImpl);
  }
  imports = importsImpl;
  if (elementsToInvalidate) {
    for (const callback of elementsToInvalidate.values()) {
      callback();
    }
  }
  elementsToInvalidate = undefined;
}

/**
 * Registers a callback that is fired when the dynamic imports have loaded.
 *
 * A callback new callback will override the old callback for an element associated with the callback has already been
 * registered.
 *
 * @param key for tracking the callback owner
 * @param cb the callback function to be called
 */
export function subscribeImportsLoaded(key: Object, cb: () => void) {
  elementsToInvalidate?.set(key, cb);
}

/**
 * Removes the associated callback from the subscribe list.
 * @see subscribeImportsLoaded
 * @param key callback owner
 */
export function unsubscribeImportsLoaded(key: Object) {
  elementsToInvalidate?.delete(key);
}
