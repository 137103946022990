import { FC, createElement, Fragment } from 'react';
import { createRoot, Root } from 'react-dom/client';

/**
 * @public
 */
export abstract class ReactCustomElement<
  T extends {
    /** @internal */
    props: {};
  }
> extends HTMLElement {
  /**
   * @internal
   */
  private _validationTimerId = 0;
  /**
   * @internal
   */
  private _root: Root | undefined;
  /**
   * @internal
   */
  private _needsUnmount: boolean = false;

  /**
   * The FunctionComponent used to render the element.
   * @internal
   */
  protected abstract _getRenderer(): FC<T['props']> | undefined;

  /**
   * Returns the properties to apply to the renderer, or null
   * if the renderer's properties can not be satisfied.
   * @internal
   */
  protected abstract getRenderProps(): T['props'] | null;

  /**
   * We can't use isConnected from the Node class (https://developer.mozilla.org/en-US/docs/Web/API/Node/isConnected)
   * The custom element polyfill we use for Edge support does not set it (ever).
   *
   * FIXME: once we drop non-Chromium Edge support, we can use isConnected instead.
   * @internal
   */
  private _connected = false;
  /**
   * @internal
   */
  protected _getConnected() {
    return this._connected;
  }

  /**
   * @internal
   */
  protected _invalidateProps() {
    if (this._getConnected()) {
      this._queueRender();
    }
  }

  /**
   * @internal
   */
  private _queueRender() {
    if (this._validationTimerId === 0) {
      this._validationTimerId = window.setTimeout(() => {
        this._validationTimerId = 0;
        this._render();
      }, 0);
    }
  }

  /**
   * @internal
   */
  private _render = () => {
    if (this._needsUnmount) {
      // unmount even if when connected to get new refs
      this._root?.unmount();
      this._root = undefined;
      this._needsUnmount = false;
    }
    if (this._connected && !this._root) {
      this._root = createRoot(this);
    }
    const renderer = this._getRenderer();
    if (!renderer) {
      if (this._root) {
        this._root.render(createElement(Fragment));
      }
      return;
    }

    const props = this._root && this.getRenderProps();
    // Block invalidation on getRenderProps
    clearTimeout(this._validationTimerId);
    this._validationTimerId = 0;

    if (props) {
      this._root!.render(createElement(renderer, props));
    } else if (this._root) {
      this._root.render(createElement(Fragment));
    }
  };

  /**
   * @internal
   */
  connectedCallback() {
    this._connected = true;
    this._queueRender();
  }

  /**
   * @internal
   */
  disconnectedCallback() {
    this._connected = false;
    this._needsUnmount = true;
    this._queueRender();
  }

  /**
   * This callback gets called anytime an observed attribute is changed.
   * @internal
   */
  attributeChangedCallback(_name: string, _old: string | null, _value: string | null) {
    this._invalidateProps();
  }

  /**
   * Subclasses should override to return their own attribute list.
   * That list should include the observed attributes of the super class.
   * @internal
   */
  static get observedAttributes(): string[] {
    return [];
  }
}
